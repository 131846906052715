import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { GatsbyImage, getImage } from "gatsby-plugin-image"


const ModalCard = (props) => {
	const {
		id,
		name,
		bio,
		photo,
		title
	} = props.props;
	
	const cardImage = getImage(photo.file)
	const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
	<>
		<div class="col-sm-6  mb-3" >
			<div className="card team-card shadow">
				<div className="card-body p-3">
					<div className="d-flex align-items-center justify-content-between">
						<div className="avatar team-member rounded-circle d-inline-block">
							<GatsbyImage alt={photo.alt} image={cardImage} />
						</div>
						<div className="p-3 flex-grow-1">
							<a href="#" className="h6 text-sm">{name}</a>
						</div>
						<Button variant="restream-green" onClick={handleShow}>Bio</Button>
					</div>
				</div>
			</div>
		</div>
		
		
	
	  	<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
		  		<Modal.Title>{name}</Modal.Title>
			</Modal.Header>
			<Modal.Body><span className="text-restream-dark-blue pb-4"
			dangerouslySetInnerHTML={{__html: bio.childMarkdownRemark.html,}}/>
			</Modal.Body>
			<Modal.Footer>
		    	<Button variant="restream-green" onClick={handleClose}>Close</Button>
			</Modal.Footer>
	  	</Modal>
	</>
  );
}

export default ModalCard;