import React from "react";
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const InfographicModule = (props) => {
	const {
		headline,
		bodyText
	} = props.props;
	
	return (
		<div className="container infographic">
			<div className="row">
				<div className="col-sm-6">
					<h4>{headline}</h4>
					<div className="info-key">
						<p><span className="key bg-restream-vibrant-green"></span>Commercial and Industrial Waste</p>
						<p><span className="key bg-restream-dark-green"></span>Municipal Solid Waste (MSW)</p>
					</div>
					<div className="body-text">{renderRichText(bodyText) }</div>
				</div>
				<div className="col-sm-6">
					<figure className="pie-chart">
						<figcaption className="chart-caption">
							<span className="text-restream-dark-green"></span> 300 million tons<br/>
							<span className="text-restream-vibrant-green"></span> 7 billion Tons
						</figcaption>
					</figure>
				</div>
				{/*
				<div className="col-sm-6 d-flex justify-content-center pb-5">
					<div className="big-circle">
						<div className="big-stat">7 billion tons</div>
						<div className="small-stat-wrapper">
							<div className="small-circle"></div>
							<div className="small-line"></div>
							<div className="small-stat">300 million tons</div>
						</div>
					</div>
				</div>
				*/}
			</div>
		</div>
	)
}
export default InfographicModule