import React from "react";
import CardOverlay from "../CardOverlay"

const CardGroup = (props, company, container) => {
	
	const {
		cards
	 } = props.props;
	 
 return (
	<>
		<div className={`${props.container}  card-group`}>
			<div className="row">
				{cards.map((card) => (
					<CardOverlay key={card.id} props={card}  />
				))}
			</div>
	   </div>
	 </>
   );
 };
 
 export default CardGroup;
