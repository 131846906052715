import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

library.add(fab, fas)



const Navigation = (props, type) => {
  const { logo } = props.navigation
  const { sections } = props.navigation
  const company = props.company
  const settings = props.settings
  const logoImage = getImage(logo.file)
  
  const [navOpen, setNavOpen] = useState(false)
  const toggleNavOpen = () => {
    setNavOpen(current => !current);
  };
  
      
  return (
    <header className={`header header-transparent `}>
      <a href="#main" title="Skip to main content" className="sr-only">
        Skip to main content
      </a>
      <Navbar bg="dark" variant="dark" expand="lg" className={`${navOpen && 'nav-open'}`}>
        <div className="container-fluid">
          <Navbar.Brand href="/"><GatsbyImage alt={logo.alt} image={logoImage} className="brand" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => toggleNavOpen()} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              {sections.map((section) =>
                  section.showInTopNav && (
                    section.page.map(navItem => (
                      <Nav.Link href={navItem.slug} key={navItem.id}>{navItem.navLabel}</Nav.Link>
                    ))
                  )
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  )
}

export default Navigation
