import React from "react";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const SplitCard = (props, type, company, container) => {
  const {
    headline,
    bodyText,
    image,
    cardColor,
    imageOnLeft
  } = props.props;
  
  const cardBG = 'bg-restream-'+cardColor.replace(/\s+/g, '-').toLowerCase();
  
  const cardImage = getImage(image.file)
 
  return (
    <>
      <div className={`${props.container}  split-card`}>
        <div className={`row align-items-md-center text-left ${imageOnLeft === false ? 'justify-content-end' : ''}`}>
          <div className={`card ${cardBG} shadow-lg col-lg-6 ${imageOnLeft === false ? 'order-1 mr--100' : 'order-2 ml--100'}`}>
            <div className="card-body">
              <h2>{headline}</h2>
              <div>
                <div className="body-text">{renderRichText(bodyText)}</div>
              </div>
            </div>
          </div>
          <div className={`col-lg-6 mb-4 mb-lg-0 ${imageOnLeft === false ? 'order-2' : 'order-1'} `}>
            <GatsbyImage alt={image.alt} image={cardImage} className="img-fluid" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SplitCard;
