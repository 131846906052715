import React from "react";
import ColumnList from "../ColumnList"

const ColumnListModule = (props) => {
	const {
		headline,
		listColumns
	 } = props.props;
		 
	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-sm-8 text-center pb-5">
					<h3>{headline}</h3>
				</div>
			</div>
			<div className="row justify-content-center">
				{listColumns.map((list) => (
					<ColumnList key={list.id} props={list} />
				))}
			</div>
		</div>
	)
}

export default ColumnListModule