import React from "react";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { Link } from "gatsby";

const CTA = (props, company, container) => {
	const {
		headline,
		bodyText,
	} = props.props;
	

	const options = {
		renderNode: {
		  [BLOCKS.LIST_ITEM]: (node, children) => {
			return <li className="restream_li">{children[0].props.children[0]}</li>;
		  },
		  [BLOCKS.PARAGRAPH]: (node, children) => {
			return <p>{children}</p>;
		  },
		  
		  [INLINES.EMBEDDED_ENTRY]: (node) => {},
		  [INLINES.HYPERLINK]: (node, children) => {
			if (node.data.uri.charAt(0) === "/") {
			  return (
				<Link
				  to={node.data.uri}
				  className={`restream_link`}
				>
				  {node.content[0].value}
				</Link>
			  );
			} else {
			  return (
				<a
				  href={node.data.uri}
				  className={`restream_link`}
				  target="_blank"
				>
				  {node.content[0].value}
				</a>
			  );
			}
		  },
		},
		renderText: (text) => {
		  return text.split("\n").reduce((children, textSegment, index) => {
			return [...children, index > 0 && <br key={index} />, textSegment];
		  }, []);
		},
	  };
	  
	
	return (
		<div className={`${props.container}  cta-module`}>
			<div className="row justify-content-center">
				<div className="col-sm-8 text-restream-dark-blue">
					<h2 className="h4 pb-4">{headline}</h2>
					<div>{renderRichText(bodyText, options)}</div>
				</div>
				<div className="col-sm-3 align-items-center justify-content-center d-flex">
					<a href="#" className={`btn px-5 btn-restream-vibrant-green text-restream-dark-blue`}>About Us</a>
				</div>
			</div>
		</div>
	);
};
  
export default CTA;
