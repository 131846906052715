import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/'
import Navigation from '../components/Navigation'
import ModuleWrapper from '../components/ModuleWrapper'
import Footer from '../components/Footer/'

import '../scss/starter.scss'

const PageTemplate = ({ data }) => {
  const { 
    slug, 
    settings, 
    seo, 
    company, 
    navigation, 
    moduleWrapper, 
    footer 
  } = data.contentfulPage


  return (
    <Layout slug={slug} settings={settings} seo={seo}>
      <Navigation
        company={company}
        settings={settings}
        navigation={navigation}
        slug={slug}
      />
      {moduleWrapper &&
        moduleWrapper.map((mWrapper) => {
          return (
            <ModuleWrapper
              type={mWrapper.module.sys.contentType.sys.id}
              props={mWrapper}
              settings={settings}
              company={company}
              key={mWrapper.id}
            />
          )
        })}
      <Footer props={footer} company={company} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      name
      slug
      seo {
        pageTitle
        description
        robots
        twitterTitle
        twitterCreator
        ogTitle
        ogImageWidth
        ogImageHeight
      }

      company {
        id
        name
        address {
          raw
        }
        email
      }
      navigation {
        id
        name
        logo {
          file {
            url
            gatsbyImageData(formats: WEBP, width: 200)
          }
          alt
        }
        sections {
          id
          name
          showInTopNav
          page {
            name
            slug
            navLabel
            id
          }
        }
      }
      moduleWrapper {
        id
        name
        sliceSize
        container
        backgroundColor
        extraClasses
        module {
          ... on ContentfulHero {
            name
            headline
            heroSize
            backgroundPosition
            bodyText {
              raw
            }
            videoBackground {
              url
            }
            image {
              alt
              file {
                gatsbyImageData(layout: FULL_WIDTH)
                description
                url
                publicUrl
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          } 
          ... on ContentfulSplitCard {
            id
            name
            headline
            bodyText {
              raw
            }
            cardColor
            imageOnLeft
            image {
              alt
              file {
                gatsbyImageData(layout: FULL_WIDTH)
                description
                url
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulCardGroup {
            id
            name
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            cards {
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              id
              bodyText {
                id
                childMarkdownRemark {
                  html
                }
              }
              headline
              image {
                alt
                file {
                  gatsbyImageData (
                    width: 600
                  )
                }
              }
            }
          }
          ... on ContentfulSimpleModule {
            id
            name
            headline
            eyebrow
            bodyText {
              raw
            }
            type
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            image {
              alt
              file {
                gatsbyImageData
              }
            }
          }
          ... on ContentfulCtaModule {
            id
            name
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            headline
            bodyText {
              raw
            }
            button {
              color
              destination
              extraClasses
              id
            }
          }
          ... on ContentfulFloatingItemsModule {
            id
            name
            eyebrow
            headline
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            floatingItems {
              backgroundColor
              id
              itemSize
              label
              name
              textColor
            }
          }
          ... on ContentfulTeamModule {
            id
            name
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            teamMembers {
              id
              bio {
                childMarkdownRemark {
                  html
                }
              }
              name
              title
              photo {
                alt
                file {
                  gatsbyImageData
                }
                name
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
          }
          ... on ContentfulServicesModule {
            id
            name
            headline
            eyebrow
            services {
              name
              icon
              headline
              fullCopy {
                raw
              }
              excerpt {
                raw
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulColumnListModule {
            id
            name
            listColumns {
              color
              headline
              id
              list {
                raw
              }
            }
            headline
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulVideoModule {
            id
            name
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            youTubeLink
          }
          ... on ContentfulInfographicModule {
            id
            name
            bodyText {
              raw
            }
            headline
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulContactModule {
            id
            name
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            bodyText {
              raw
            }
            contactBlock {
              raw
            }
            headline
            image {
              file {
                url
                gatsbyImageData
              }
            }
          }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      footer {
        id
        name
        copyright
        footerNavigationPages {
          name
          slug
          navLabel
          id
        }
        logo {
          alt
          file {
            gatsbyImageData (
              width: 80
            )
          }
        }
        socialLinks {
          id
          link
          name
          platform
        }
      }
    }
  }
`

export default PageTemplate
