import React from "react";

const FloatingItem = (props) => {
	const {
		label,
		backgroundColor,
		textColor,
		itemSize
	} = props.props;
	
	const bgColor = backgroundColor.replace(/\s+/g, '-');
	
	return (
		<div className={`icon-floating bg-restream-${bgColor} floating-item-${itemSize} floating col-4 col-sm-2`}>
			  <span className={`text-${textColor}`}>{label}</span>
		</div>
	)
}
export default FloatingItem