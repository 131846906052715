import React from 'react'
import CardGroup from '../CardGroup'
import ColumnListModule from '../ColumnListModule'
import CTA from '../CTA'
import FloatingItemsModule from '../FloatingItemsModule'
import Hero from '../Hero'
import InfographicModule from '../InfographicModule'
import SplitCard from '../SplitCard'
import SimpleModule from '../SimpleModule'
import TeamModule from '../TeamModule'
import VideoPlayer from '../Video'
import ServicesModule from '../ServicesModule/'

const ModuleWrapper = (props, type, settings, company) => {
  const {
    sliceSize,
    backgroundColor,
    container,
    extraClasses,
  } = props.props;
  
  const slice =
    sliceSize === "Large" ? "slice-lg" : sliceSize === "XL" ? "slice-xl" : "slice";
  const containerSize =
    container === "Fluid Container" ? "container-fluid" : container === "Default Container" ? "container" : "container-fluid";
  const sliceBackground = backgroundColor && `bg-${backgroundColor}`;
  
  const isHero = props.type === "hero" ? "hero" : "";
  const heroSize = props.props.module.heroSize;
    
  
  return (
      <section
        className={`${sliceBackground} ${slice} ${isHero} ${heroSize ? isHero+'-'+heroSize : '' }  ${extraClasses} ${backgroundColor !== 'white' && 'bg-restream-'+backgroundColor} bg-cover bg-size--cover`}
        style={{
          backgroundImage: `url(${ props.type === "hero" && props.props.module.image ? props.props.module.image.file.url : 'none'})`, 
          backgroundPosition: isHero && props.props.module.backgroundPosition}}
      >
        {props.type === "hero" && (
          <>
          
            {heroSize === "large" && (
              <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                <source src={props.props.module.videoBackground.url} type="video/mp4" />
              </video>
            )}
          
            <span className="mask bg-hero-gradient"></span>
            <Hero
              props={props.props.module}
              company={props.company}
              backgroundColor={backgroundColor}
              container={containerSize}
            />
          </>
        )}

        {props.type === "cardGroup" && (
          <CardGroup
            props={props.props.module}
            company={props.company}
            backgroundColor={backgroundColor}
            container={containerSize}
          />
        )}
        
        {props.type === "columnListModule" && (
          <ColumnListModule
            props={props.props.module}
            company={props.company}
            backgroundColor={backgroundColor}
            container={containerSize}
          />
        )}
        
        {props.type === "ctaModule" && (
          <CTA
            props={props.props.module}
            company={props.company}
            backgroundColor={backgroundColor}
            container={containerSize}
          />
        )}
       
        {props.type === "floatingItemsModule" && (
          <FloatingItemsModule
            props={props.props.module}
            company={props.company}
            backgroundColor={backgroundColor}
            container={containerSize}
          />
        )}
        
        {props.type === "infographicModule" && (
          <InfographicModule
            props={props.props.module}
            company={props.company}
            backgroundColor={backgroundColor}
            container={containerSize}
          />
        )}
        
        {props.type === "teamModule" && (
          <TeamModule
            props={props.props.module}
            company={props.company}
            backgroundColor={backgroundColor}
            container={containerSize}
          />
        )}
        
        {(props.type === "simpleModule" || props.type === "contactModule") && (
          <SimpleModule
            props={props.props.module}
            company={props.company}
            backgroundColor={backgroundColor}
            container={containerSize}
          />
        )}
        
        {props.type === "splitCard" && (
          <SplitCard
            props={props.props.module}
            company={props.company}
            backgroundColor={backgroundColor}
            container={containerSize}
          />
        )}
        
        {props.type === "servicesModule" && (
          <ServicesModule
            props={props.props.module}
            company={props.company}
            backgroundColor={backgroundColor}
            container={containerSize}
          />
        )}
                
        {props.type === "videoModule" && (
          <VideoPlayer
            props={props.props.module}
            company={props.company}
            backgroundColor={backgroundColor}
            container={containerSize}
          />
        )}
        
      </section>
  );
};

export default ModuleWrapper
