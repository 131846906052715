import React from "react";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import MailChimpForm from '../MailchimpFormContainer';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

library.add(fab);

const Footer = ({props, company}) => {
  const {
    logo,
    socialLinks,
    footerNavigationPages,
    copyright
  } = props;
  
 const { name, address, email } = company;
  
  const footerLogo = logo && getImage(logo.file)
    
  
  
  return (
    <footer id="footer-main">
      <div className="footer footer-dark bg-restream-dark-blue p-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="col-12">
                <ul className="nav list-inline footer-nav">
                  {footerNavigationPages.map((footerItem) =>
                      (
                        <li className="list-inline-item px-3 py-1" key={footerItem.id}><a href={footerItem.slug}>{footerItem.navLabel}</a></li>
                      )
                  )}
                </ul>
              </div>
              <div className="col-12 copyright p-3">
                <div className="address">{renderRichText(company.address) }</div>
                {copyright}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="col-12 logo text-center">
                <GatsbyImage alt={logo.alt} image={footerLogo} className="img-fluid" />
              </div>
              <div className="col-12 social-links">
                <ul className="justify-content-center pt-3">
                  {socialLinks.map((link) => (
                    <li className="list-item" key={link.id}>
                      <a href={link.link} rel="noopener noreferrer" target="_blank" className="badge  badge-circle">
                        <span className="social-icon text-white">
                          <FontAwesomeIcon icon={["fab", link.platform==="Facebook" ? "facebook-f" : link.platform.toLowerCase()]} />{" "}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-sm-4">
              
              <MailChimpForm />
            </div>
            
          </div>
          
        </div>
      </div>
    </footer>
  );
};

export default Footer;
