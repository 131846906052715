import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { renderRichText } from 'gatsby-source-contentful/rich-text';


library.add(fab, fas)


const ServiceCard = (props) => {
	const {
		headline,
		excerpt,
		fullCopy,
		icon,
	 } = props.props;
	 	
	
	return (
	 <div className={`col-sm-4 `}>
	 	<div className="card card-service">
		 	<span className="service-icon text-white text-center">
			 <FontAwesomeIcon icon={["solid", icon]} />
		    </span>
			<h6 className="initial-headline">{headline}</h6>
			<div className="excerpt">{renderRichText(excerpt) }</div>
	 	</div>	
	 </div>
   );
 };
 
 export default ServiceCard;
