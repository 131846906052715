import React, { useState } from "react";
import CardOverlay from "../CardOverlay"
import Button from 'react-bootstrap/Button'
import ModalCard from '../ModalCard'

const TeamModule = (props, company, container, simpleModuleStyle) => {
	const {
		name,
		teamMembers
	} = props.props;
	
	const [show, setShow] = useState(false);
	
	  const handleClose = () => setShow(false);
	  const handleShow = () => setShow(true);
	
	
	return (
	<>
		<div className={`${props.container}  card-group ${props.props.sys.contentType.sys.id === "teamModule" && 'team-group'}`}>
			<div className="row justify-content-center">
				<div className="col-sm-8 mb-5">
					<h2>Meet the Team</h2>
				</div>
			</div>
			
			<div className="row justify-content-center">
				
					{teamMembers.map((member) => (
						<ModalCard key={member.id} props={member} />
					))}
			</div>
			
	   </div>
	 </>
	);
};
	  
export default TeamModule;