import React, { useState, useEffect } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";

const CustomForm = ({ status, message, onValidated }) => {

	const [email, setEmail] = useState('');
	
	useEffect(() => {
		if(status === "success") clearFields();
	  }, [status])
	
	  const clearFields = () => {
		setEmail('');
	  }

	
	const handleSubmit = (e) => {
		e.preventDefault();
		email &&
		email.indexOf("@") > -1 &&
		onValidated({
			EMAIL: email
		});
	}


	return (
		<form 
			className="mc__form"
			onSubmit={(e) => handleSubmit(e)}
		  >
			<h5 className="signup-headline">Get ReStream news and insights right in your inbox!</h5>
			
			{status === "sending" && (
			  <div className="mc__alert mc__alert--sending">
				sending...
			  </div>
			)}
			{status === "error" && (
			  <div 
				className="mc__alert mc__alert--error"
				dangerouslySetInnerHTML={{ __html: message }}
			  />
			)}
			{status === "success" && (
			  <div
				className="mc__alert mc__alert--success"
				dangerouslySetInnerHTML={{ __html: message }}
			  />
			)}
			
			<div className="form-group d-flex mailchimp-footer-form">
				<input
			  		type="email"
			  		className="form-control email-field"
			  		id="email"
			  		name="email"
			  		value={email}
					placeholder="email"
					onChange={(e) => setEmail(e.target.value)}
				/>
				<button type="submit" className="btn btn-restream-vibrant-green btn-submit" onSubmit={handleSubmit}>Sign Up</button>
			</div>
		
			
		  </form>
	);
};

const MailchimpForm = props => {
	
	const u = `f72aeb18158a1263165ae2510`;
	const id = `1260cbe823`;
	
	const postUrl = `https://reastreamenergy.us14.list-manage.com/subscribe/post?u=${u}&id=${id}`;
	
	return (
		
		<div className="mc__form-container">
			<MailchimpSubscribe
				url={postUrl}
				render={({ subscribe, status, message }) => (
					<CustomForm
						status={status} 
						message={message}
						onValidated={formData => subscribe(formData)}
					/>
				)}
			/>
		</div>
	);
};

export default MailchimpForm;