import React from "react";
import FloatingItem from "../FloatingItem"

const FloatingItemsModule = (props) => {
	const {
		headline,
		eyebrow,
		floatingItems
	 } = props.props;
	 	
	return (
		<>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-sm-8 text-center">
						<h6>{eyebrow}</h6>
						<h3>{headline}</h3>
					</div>
				</div>
			</div>
			<div className="container mt-5">
				<div className="row floating-items-row">
					{floatingItems.map((item) => (
						<FloatingItem key={item.id} props={item} />
					))}
				</div>
			</div>
		</>
	)
}

export default FloatingItemsModule