import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import { useContentfulImage } from "gatsby-source-contentful/hooks";

const CardOverlay = (props) => {
	const {
		headline,
		bodyText,
		image,
		name,
		bio,
		photo
	 } = props.props;
	 	
	const isTeam = 	props.props.sys.contentType.sys.id 
	const cardImage = isTeam === "teamMember" ? getImage(photo.file) : getImage(image.file)
		
	
	return (
	 <div className={`col-sm-4 ${isTeam}-col`}>
	 	<div className={`card card-overlay card-hover-overlay ${isTeam}`}>
 			<h6 className="initial-headline">{isTeam === "teamMember" ? name : headline}</h6>
	 		<div className="inner-overlay-card">
				<div className="figure">
					<GatsbyImage alt={isTeam === "teamMember" ? photo.alt : image.alt} image={cardImage} className="img-fluid" />	
				</div>
				<div className="card-img-overlay d-flex">
					<div className="overlay-text mt-auto p-3">
						{isTeam === "teamMember" ? (
							<div>
								<h6 className="text-restream-dark-blue">{name}</h6>
								<span
									className="text-restream-dark-blue pb-4"
									dangerouslySetInnerHTML={{
								  	__html: bio.childMarkdownRemark.html,
									}}
							  	/>
							</div>
						) : (
							bodyText && (
								<div>
									<h6 className="text-restream-dark-blue">{headline}</h6>
					  				<span
										className="text-restream-dark-blue pb-4"
										dangerouslySetInnerHTML={{
						  				__html: bodyText.childMarkdownRemark.html,
										}}
					  				/>
								</div>
				  			)
						)}
					</div>
					
				</div>
			</div>
		</div>
	 </div>
   );
 };
 
 export default CardOverlay;
