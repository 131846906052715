import React from "react";
import Ratio from 'react-bootstrap/Ratio';

const VideoPlayer = (props) => {
	const {
		youTubeLink
	} = props.props;
	
	return (
		<div className="container">
			<div className="row">
				<div className="col-12">
				<Ratio aspectRatio="16x9">
					<iframe src={youTubeLink} title="FOCUS on Pottstown" allowFulScreen />
			  	</Ratio>
				</div>
			</div>
		</div>
	)
}
export default VideoPlayer