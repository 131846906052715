import React from "react";
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const Hero = (props, type, company) => {
  const {
    headline,
    bodyText,
    image,
    heroSize
  } = props.props;
  

  return (
    <>
      {heroSize === "large" && (
        <div className="container">
          <div className="row">
            <h1 className="headline">{headline}</h1>
            <div>{renderRichText(bodyText)}</div>
          </div>
        </div>
        
      )}
      
    </>
  );
};

export default Hero;
