import React from "react";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const SimpleModule = ({props, company, container, simpleModuleStyle}) => {
	const {
		eyebrow,
		type,
		headline,
		bodyText,
		image,
		contactBlock
	} = props;
	
	const simpleImage = image && getImage(image.file)
	
	const { name, address, email } = company;
	
	
	let simpleType = "";
	  switch (type) {
		case "full-width text":
		  simpleType = "full-width-text";
		  break;
		case "headline two column":
		  simpleType = "headline-two-col";
		  break;
		case "text and image":
		  simpleType = "text-and-image";
		  break;
		default:
		  simpleType = "";
	  }
	  
	const options = {
		renderNode: {
		  [BLOCKS.LIST_ITEM]: (node, children) => {
			return <li className="restream_li">{children[0].props.children[0]}</li>;
		  },
		  [BLOCKS.PARAGRAPH]: (node, children) => {
			return <p>{children}</p>;
		  },
		  
		  [INLINES.EMBEDDED_ENTRY]: (node) => {},
		  [INLINES.HYPERLINK]: (node, children) => {
			if (node.data.uri.charAt(0) === "/") {
			  return (
				<Link
				  to={node.data.uri}
				  className={`restream_link`}
				>
				  {node.content[0].value}
				</Link>
			  );
			} else {
			  return (
				<a
				  href={node.data.uri}
				  className={`restream_link`}
				  target="_blank"
				>
				  {node.content[0].value}
				</a>
			  );
			}
		  },
		},
		renderText: (text) => {
		  return text.split("\n").reduce((children, textSegment, index) => {
			return [...children, index > 0 && <br key={index} />, textSegment];
		  }, []);
		},
	  };
	  
	
	return (
		<>
		{props.sys.contentType.sys.id === "simpleModule" && (
			<div className={`${props.container}  simple-module`}>
				{simpleType === "full-width-text" && (
					<div className="row justify-content-center full-width-text">
						<div className="col-sm-8">
							<h2 className="h4">{headline}</h2>
							<div>{renderRichText(bodyText, options)}</div>
						</div>
					</div>
				)}
				{simpleType === "headline-two-col" && (
					<div className="row justify-content-center headline-two-col">
						<div className="col-sm-5">
							<h2>{headline}</h2>
						</div>
						<div className="col-sm-5">
							<div>{renderRichText(bodyText, options)}</div>
						</div>
					</div>
				)}
				{simpleType === "text-and-image" && (
					<div className="row justify-content-center text-and-image">
						<div className="col-sm-6 image-col">
							<GatsbyImage alt={image.alt} image={simpleImage} className="img-fluid" />
						</div>
						<div className="col-sm-6">
							{eyebrow && (
								<small className="text-muted pb-3">{eyebrow}</small>
							)}
							<h2 className="h3">{headline}</h2>
							<div>{renderRichText(bodyText, options) }</div>
						</div>
					</div>
				)}
			</div>
		)}
		{props.sys.contentType.sys.id === "contactModule" && (
			<div className={`${props.container}  contact-module`}>
				<div className="row justify-content-center full-width-text">
					<div className="col-sm-5">
						<h1 className="h4">{headline}</h1>
						<div>{renderRichText(bodyText, options)}</div>
						<form name="contact" netlify-honeypot="bot-field" method="POST" data-netlify="true" action="/success">
						<input type="hidden" name="bot-field" />
						<input type="hidden" name="form-name" value="contact" />
						  <div className="mb-3">
							<label for="name" className="form-label">Name</label>
							<input type="text" className="form-control" id="name"
							name="name" placeholder="Name" aria-describedby="name" />
						  </div>
						  <div className="mb-3">
							<label for="email" className="form-label">Email</label>
							<input type="email"
							name="email" placeholder="email address" className="form-control" id="email" />
						  </div>
						  <div className="mb-3">
							  <label for="phone" className="form-label">Phone Number</label>
							  <input type="phone" placeholder="(###)###-####" className="form-control" 
							  name="phone" id="phone" />
						  </div>
						  <div className="mb-3">
								<label for="details" className="form-label">Please add any details:</label>
								<textarea class="form-control" id="details" placeholder="You can manually resize this textarea" name="details" rows="3"></textarea>
							</div>
						  <button type="submit" className="btn btn-restream-vibrant-green mt-3">Contact</button>
						</form>
					</div>
					<div className="col-sm-4 offset-sm-2 contact-image"
					style={{
					  backgroundImage: `url(${image.file.url})`, 
					  backgroundPosition: "center center"}}
					>
						<div className="contact-info-card p-4">
							<h6>Contact</h6>
							<div>{renderRichText(company.address) }</div>
							<div>{company.email}</div>
						</div>
					</div>
				</div>
			</div>
		)}
		</>
	);
};
  
export default SimpleModule;
