import React, { Fragment } from "react";
import Helmet from "react-helmet";

const Layout = ({ slug, settings, seo, date, children }) => {

  const {
    pageTitle,
    description,
    robots,
    twitterTitle,
    twitterCreator,
    twitterImage,
    ogTitle,
    ogImage,
    ogImageWidth,
    ogImageHeight,
  } = seo;

  const url = slug === "home" ? "" : "" + slug;

  const ogImageUrl = ogImage ? `${ogImage?.file.file.url}` : "";

  const twitterImageUrl = twitterImage ? `${twitterImage?.file.file.url}` : "";

  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url,
      name: "",
      alternateName: "",
    },
  ];

  // Page SEO Schema
  schemaOrgJSONLD.push({
    "@context": "http://schema.org",
    "@type": "WebPage",
    url,
    name: pageTitle,
  });

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="./src/images/apple-touch-icon.png"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          rel="preload"
          as="style"
          href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400&family=Raleway:wght@600;700;800;900&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400&family=Raleway:wght@600;700;800;900&display=swap"
          media="print"
          onload="this.media='all'"
        />
        <noscript>
          {`<link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400&family=Raleway:wght@600;700;800;900&display=swap"/> `}
        </noscript>
        <link rel="manifest" href="./src/images/site.webmanifest" />
        <link rel="robots" content={robots} />
        <title>{pageTitle} | Restream Energy</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
        <meta
          name="facebook-domain-verification"
          content="b5qlrbk9pnfor4lhfh4nemyovh6bjc"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={ogImageUrl} />
        <meta property="og:image:width" content={ogImageWidth} />
        <meta property="og:image:height" content={ogImageHeight} />
        <meta property="og:image:alt" content={ogTitle} />
        <meta property="og:site_name" content="Restream Energy" />
        <meta property="og:see_also" content="" />
        <meta property="og:see_also" content="" />
        <meta property="og:see_also" content="" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:title" content={twitterTitle} />
        <meta name="twitter:site" content="" />
        <meta name="twitter:image" content={twitterImageUrl} />
        <meta name="twitter:creator" content={twitterCreator || ""} />
        <meta name="twitter:url" content={url} />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="2 minutes" />
        <meta property="twitter:image:alt" content={twitterTitle} />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.markerConfig={destination:'614fc7f38ee0ba6c42c58bb9'}`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `!function(e,r,t){if(!e.__Marker){e.__Marker={};var a=[],s={__cs:a};for(const e of["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","on","off"])s[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),a.push(r)};e.Marker=s;var n=r.createElement("script");n.async=1,n.src="https://edge.marker.io/latest/shim.js";var o=r.getElementsByTagName("script")[0];o.parentNode.insertBefore(n,o)}}(window,document);`,
          }}
        />
      </Helmet>
      <main id="main">{children}</main>
    </Fragment>
  );
};

export default Layout;
