import React from "react";
import Service from "../Service"

const ServicesModule = (props, company, container) => {
	
	const {
		headline,
		eyebrow,
		services
	 } = props.props;

	
	return (
	<>
		<div className={`${props.container}`}>
			<div className="row justify-content-center">
				<div className="col-sm-8 mb-5">
					{eyebrow && (
						<small className="text-muted pb-3">{eyebrow}</small>
					)}
					<h2>{headline}</h2>
				</div>
			</div>
			<div className="row">
				{services.map((service) => (
					<Service key={service.id} props={service}  />
				))}
			</div>
	   </div>
	 </>
	);
};
	  
export default ServicesModule;