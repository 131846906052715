import React from "react";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';

const ColumnList = (props) => {
	const {
		color,
		headline,
		list
	} = props.props;
	
	const listColor = color.replace(/\s+/g, '-');
	
	const options = {
		renderNode: {
		  [BLOCKS.LIST_ITEM]: (node, children) => {
			return <li className="col_li">{children[0].props.children[0]}</li>;
		  },
		  [BLOCKS.PARAGRAPH]: (node, children) => {
			return <p>{children}</p>;
		  },
		  
		  [INLINES.EMBEDDED_ENTRY]: (node) => {},
		},
		renderText: (text) => {
		  return text.split("\n").reduce((children, textSegment, index) => {
			return [...children, index > 0 && <br key={index} />, textSegment];
		  }, []);
		},
	  };
	
	return (
		<div className={`col-sm-4 column-list`}>
			<div className={`list-header bg-restream-${listColor}`}></div>
			<h5>{headline}</h5>
			<div className="list-wrapper">{renderRichText(list, options)}</div>
		</div>
	)
}
export default ColumnList